import React from "react";

import Layout from "../layouts/zh";
import SEO from "../components/seo";

import { AccountBlocked } from "../components/TWComponents/AccountBlocked";

const AccountBlockedPage = (props) => {
  return (
    <Layout location={props.location} showBookNowButton={false}>
      <SEO
        title="Account Deactivated"
        keyword="Account Deactivated"
        decription="Account Deactivated"
      />
      <AccountBlocked
        title="帐户已停用"
        subDesciption="由于您的账户在过去12个月内处于非活动状态，目前已被停用。"
        decription='请<a href="mailto:info@ayanarewards.com?subject=AYANA%20Rewards%20Account%20Reactivation" target="_top" className="!tw-text-[#0095D8]">联系我们</a>重新激活您的帐户或与联系<a href="mailto:info@ayanarewards.com?subject=AYANA%20Rewards%20Account%20Reactivation">info@ayanarewards.com</a>寻求帮助。'
        button="Account Deactivated"
      />
    </Layout>
  );
};

export default AccountBlockedPage;
